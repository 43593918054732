import { useUser } from "@wwc/hooks/user";
import { fmtUserName } from "@wwc/utils";
import { useEffect, useState } from "react";

const greetings = [
	"Hey",
	"Hello",
	"Hi",
	"Howdy",
	"Bonjour",
	"Good day",
	"Aloha",
	"Namaste",
	"Howdy-do",
	"Cheerio",
	"G'day",
	"Good day",
	"Salute",
	"Ciao",
	"Olá",
];

const HomeView: React.FC = () => {
	const [greeting, setGreeting] = useState<string>();
	const user = useUser();
	const name = user && fmtUserName(user);

	useEffect(
		() =>
			setGreeting(
				greetings[Math.ceil(Math.random() * greetings.length - 1)],
			),
		[],
	);

	return (
		<div className="container">
			<div className="space-y-6 px-4 py-5 sm:p-6">
				<h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
					<span className="block capitalize">
						{greeting}, {name}
					</span>
					<span className="block text-indigo-600">
						Today is{" "}
						{new Date().toLocaleDateString("en-GB", {
							weekday: "long",
							day: "2-digit",
							month: "long",
						})}
						.
					</span>
				</h2>
				<div className="px-4 py-6 sm:px-0">
					<div className="flex h-96 items-center justify-center rounded-lg border-4 border-dashed border-gray-200">
						<h3 className="text-gray-400">Stats coming soon…</h3>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HomeView;

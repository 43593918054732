import { pathToRegexp, compile } from "path-to-regexp";

export enum routes {
	home = "/",
	notFound = "/notFound",
	registrationList = "/registrations",
	registrationView = "/registrations/:id",
	reportList = "/reports",
	reportView = "/reports/:id",
	userList = "/users",
	profileList = "/profiles",
	profileView = "/profiles/:id",
	accountList = "/accounts",
	accountView = "/accounts/:id",
	consultantList = "/consultants",
	consultantView = "/consultants/:id",
}

export type Route = keyof typeof routes;

const toRoute = (routeKey: Route, props?: Record<string, string>): string => {
	const route = routes[routeKey];
	return compile(route)(props);
};

const isRoute = (routeKey: Route, path: string): boolean => {
	const route = routes[routeKey];
	return pathToRegexp(route).regexp.exec(path) !== null;
};

export default routes;
export { isRoute, toRoute };

import { ProfilesContext } from "@wwc/hooks/profiles";
import { UserProvider } from "@wwc/hooks/user";
import { createRoot } from "react-dom/client";
import { Toaster } from "react-hot-toast";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";

const version = String(import.meta.env.VITE_PKG_VERSION);
const environment = String(import.meta.env.MODE);
console.info(`v${version} (${environment})`);

createRoot(document.getElementById("app")!).render(
	<BrowserRouter>
		<UserProvider>
			<ProfilesContext>
				<Toaster toastOptions={{ className: "max-w-md" }} />
				<App />
			</ProfilesContext>
		</UserProvider>
	</BrowserRouter>,
);

import { Transition, TransitionChild } from "@headlessui/react";
import { useCallback, useRef } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";

const container = document.getElementById("modal");

type Props = React.PropsWithChildren & {
	title: string;
	show: boolean;
	confirmLabel?: string;
	confirmClass?: string;
	cancelLabel?: string;
	onCancel?: () => void;
	onConfirm?: (data: FormData) => void;
};

const Modal: React.FC<Props> = ({
	show,
	title,
	confirmLabel,
	cancelLabel,
	onCancel,
	onConfirm,
	children,
}) => {
	const ts = useTranslation("labels").t;
	const formRef = useRef<HTMLFormElement>(null);
	const onSubmit = useCallback(
		(e: React.FormEvent<HTMLFormElement>) => {
			e.preventDefault();
			e.stopPropagation();
			const data = new FormData(e.currentTarget);
			onConfirm?.call(null, data);
		},
		[formRef],
	);
	const modal = (
		<Transition
			as="div"
			className="fixed inset-0 z-50 overflow-y-auto"
			show={show}
		>
			<div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
				<TransitionChild
					as="div"
					className="fixed inset-0 transition-opacity duration-300"
					aria-hidden="true"
					enterFrom="opacity-0"
					leaveTo="opacity-0"
				>
					<div className="absolute inset-0 bg-gray-500 opacity-75"></div>
				</TransitionChild>

				<span
					className="hidden sm:inline-block sm:h-screen sm:align-middle"
					aria-hidden="true"
				>
					&#8203;
				</span>
				<TransitionChild
					as="div"
					className="inline-block transform rounded-lg bg-white text-left align-bottom shadow-xl transition-all duration-300 sm:my-8 sm:w-full sm:max-w-lg sm:align-middle"
					enterFrom="opacity-0 -translate-y-10"
					leaveTo="opacity-0 -translate-y-10"
					role="dialog"
					aria-modal="true"
					aria-labelledby="modal-headline"
				>
					<form ref={formRef} onSubmit={onSubmit}>
						<div className="rounded-lg bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
							<div className="sm:flex sm:items-start">
								<div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
									<svg
										className="h-6 w-6 text-red-600"
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
										aria-hidden="true"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
										/>
									</svg>
								</div>
								<div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
									<h3
										className="text-lg font-medium leading-6 text-gray-900"
										id="modal-headline"
									>
										{title}
									</h3>
									<div className="mt-2">
										<div className="text-sm text-gray-500">
											{children}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="rounded-b-lg bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
							{onConfirm && (
								<button
									type="submit"
									className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
								>
									{confirmLabel || ts("confirm")}
								</button>
							)}
							{onCancel && (
								<button
									type="button"
									className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
									onClick={onCancel}
								>
									{cancelLabel || ts("cancel")}
								</button>
							)}
						</div>
					</form>
				</TransitionChild>
			</div>
		</Transition>
	);

	return container && createPortal(modal, container);
};

export default Modal;
